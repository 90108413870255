.footer {
  .bg-primary {
    background-image: url("../images/KBR-sand-wave_light.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include media-breakpoint-up(md) {
      background-size: 110%;
    }
  }
}

.search-widget--submit-btn-text {
  color: #fff;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }
}

.header__top-item--account {
  display: none;
}

.header__mobile-menu {
  .nav-user {
    display: none;
  }
}
