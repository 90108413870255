// Fonts

$body-font: ("Poppins", sans-serif) 400;
$bold-font: ("Poppins", sans-serif) 600;
$heading-font: ("Barlow Semi Condensed", sans-serif) 700;

// Colours
$primary: #c2b59b;
$secondary: #dfd5c3;
$grey: #919191;

// Header
$header-bg-colour: $primary;

// ***********************
// Spacing system
// ***********************
$enable-negative-margins: true;
